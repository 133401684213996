import { useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

const calcCover = element => {
  if (!element) return { width: 0, height: 0, x: 0, y: 0 }
  const videoAspect = (1.0 / 16) * 9
  const elementAspect = (1.0 / element.clientWidth) * element.clientHeight
  const rect = { x: 0, y: 0 }
  if (videoAspect > elementAspect) {
    rect.width = element.clientWidth
    rect.height = Math.floor(element.clientWidth * videoAspect)
  } else {
    rect.width = Math.floor(element.clientHeight / videoAspect)
    rect.height = element.clientHeight
  }

  if (rect.width > element.clientWidth) {
    rect.x = (element.clientWidth - rect.width) * 0.5
  } else if (rect.height > element.clientHeight) {
    rect.y = (element.clientHeight - rect.height) * 0.5
  }
  return rect
}

export default ({ children, ...props }) => {
  const ref = useRef()
  const [cover, setCover] = useState({ x: 0, y: 0, width: 0, height: 0 })

  useEffect(() => {
    const onResize = () => setCover(calcCover(ref.current))
    window.addEventListener("resize", onResize)
    onResize()
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [ref])

  return (
    <div
      ref={ref}
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      `}
      {...props}
    >
      <div
        css={css`
          position: absolute;
          left: ${cover.x}px;
          top: ${cover.y}px;
          width: ${cover.width}px;
          height: ${cover.height}px;

          & iframe {
            width: 100%;
            height: 100%;
          }
        `}
      >
        {children}
      </div>
    </div>
  )
}
