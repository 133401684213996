import { useRef, useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

const Overlay = ({
  checkSize = true,
  isOpen,
  motionKey,
  onClose,
  children,
  ...props
}) => {
  const [width, setWidth] = useState("100%")

  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return
    setWidth("100%")
  }, [ref])

  useEffect(() => {
    if (!ref.current) return
    const onResize = () => {
      if (!ref.current) return
      if (ref.current.clientHeight > window.innerHeight) {
        setWidth((window.innerWidth / 16) * 9 + "px")
      }
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [ref, width])

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          ref={ref}
          css={css`
            position: absolute;
            left: 0.4em;
            width: calc(${checkSize ? width : "100%"} - 0.8em);
            top: 0.4em;
            bottom: 0.4em;
            padding-top: 2em;
          `}
          {...props}
        >
          <motion.div
            key={motionKey || "overlay"}
            positionTransition
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{ opacity: 0, x: "100%" }}
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default Overlay
