import { useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

export default ({ txt, ...props }) => {
  const ref = useRef()
  const [longTxt, setLongTxt] = useState(txt)
  useEffect(() => {
    if (!ref.current) return
    let _txt = txt
    let i = 0
    // let's fill text until content width >= 2 * element width
    //while (ref.current.scrollWidth < ref.current.clientWidth * 20) {
    while (i < 100) {
      _txt = _txt + txt
      i++
      ref.current.innerHTML = _txt
    }
    setLongTxt(_txt)

    const onRaf = time => {
      ref.current.scrollTo((time / 50) % ref.current.scrollWidth, 0)
      raf = window.requestAnimationFrame(onRaf)
    }
    let raf = window.requestAnimationFrame(onRaf)
    return () => {
      window.cancelAnimationFrame(raf)
    }
  }, [txt, ref])

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        font-size: 300%;
        width: 100%;
        padding-top: 0.04em;

        @media (min-width: 70em) {
          font-size: 400%;
        }
      `}
      {...props}
      dangerouslySetInnerHTML={{ __html: longTxt }}
    />
  )
}
