/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useObserver } from "mobx-react"

import SEO from "../components/seo"
import Instructions from "../components/instructions"
import UserInfo from "../components/userinfo"
import Nav from "../components/nav"
import ChatRoom from "../components/chatRoom"
import Marquee from "../components/marquee"
import TwitchRoom from "../components/twitchRoom"
import Logo from "../components/logo"
import Dj from "../components/dj"

import { wuffwoff } from "../store"

const TopMarquee = () => {
  return useObserver(() => <Marquee txt={wuffwoff.text} />)
}
const IndexPage = () => {
  return (
    <div
      css={css`
        position: fixed;
        left: 0.2em;
        right: 0.2em;
        top: 0.2em;
        bottom: 0.2em;
        overflow: hidden;
      `}
    >
      <SEO title="WOFFWOFFWOFFWOFFWOFFWOFFWOFFWOFF" />
      <Dj />
      <div
        css={css`
          position: relative;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: column;
        `}
      >
        <ChatRoom />
        {false && <TwitchRoom />}
        <TopMarquee />
        <Logo
          css={css`
            width: 10em;
            margin-left: auto;
            margin-top: auto;
            fill: white;
            stroke: black;
            stroke-width: 0.5px;
            overflow: visible;
          `}
        />
        <Nav />
        <Instructions />
        <UserInfo
          css={css`
            width: 100%;
          `}
        />
      </div>
    </div>
  )
}

export default IndexPage
