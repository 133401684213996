import { useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

export default ({ channel, volume, onSetPlayer, ...props }) => {
  const ref = useRef()
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    if (!ref.current) return
    if (player) return
    const options = {
      width: 1920,
      height: 1080,
      channel: channel,
      playsinline: true,
      parent: [document.location.hostname],
    }
    setPlayer(new window.Twitch.Player(ref.current, options))
  }, [player, ref, channel])

  useEffect(() => {
    if (!player) return
    player.setVolume(typeof volume === "undefined" ? 0.5 : volume)
  }, [player, volume])

  useEffect(() => {
    if (!player || !onSetPlayer) return
    onSetPlayer(player)
  }, [player, onSetPlayer])

  useEffect(() => {
    if (!player) return
    player.setChannel(channel)
  }, [channel, player])

  return (
    <div
      ref={ref}
      css={css`
        width: 100%;
        height: 100%;
        pointer-events: all;
      `}
      {...props}
    />
  )
}
