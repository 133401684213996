import { useState, useCallback, useRef, useEffect } from "react"
import { useObserver } from "mobx-react"
import { AnimatePresence, motion } from "framer-motion"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import { user, users, rooms, twitches, dj, socket } from "../store"

const Button = ({
  showLabel,
  label,
  count,
  meta,
  icon,
  isActive,
  disabled = false,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      css={css`
        position: relative;
        filter: grayscale(${disabled ? 1 : 0});
      `}
    >
      {typeof count !== "undefined" && (
        <div
          css={css`
            position: absolute;
            z-index: 6;
            left: 0.04em;
            bottom: 0;
            font-size: 80%;
            text-decoration: ${isActive ? "underline" : "none"};
          `}
        >
          {count}
        </div>
      )}
      <motion.button
        animate={{ x: isActive ? ".4em" : "0em" }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        disabled={disabled}
        css={theme => [
          theme.resetButton,
          css`
            pointer: ${disabled ? "default" : "pointer"};
            font-size: 200%;
            display: flex;
            align-items: center;
            text-transform: uppercase;
          `,
        ]}
        {...props}
      >
        {icon && (
          <motion.img
            animate={{ scale: hover || isActive ? 1 : 0.8 }}
            src={icon}
            alt=""
            css={css`
              width: 2em;
              height: auto;
            `}
          />
        )}
        <AnimatePresence>
          {(hover || showLabel) && (
            <motion.div
              key="label"
              initial={{ scale: 0, opacity: 0 }}
              exit={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              css={css`
                position: absolute;
                left: 100%;
                top: 0;
                bottom: -1em;
                text-align: left;
                font-size: 80%;
                min-width: 13em;
                transform-origin: left center;
                display: flex;
                align-items: center;
                padding: 0 0 0 0.4em;

                @media (min-width: 60em) {
                  font-size: 60%;
                }
              `}
            >
              <div
                css={css`
                  font-size: 40%;
                `}
              >
                {meta}
              </div>
              {label}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>
    </div>
  )
}

const NavLabel = ({ children }) => (
  <div
    css={css`
      display: none;
      font-size: 80%;
      margin: 0 0 0.4em;
    `}
  >
    {children}
  </div>
)

const NavGroup = ({ children }) => (
  <div
    css={css`
      margin: 1.8em 0;
    `}
  >
    {children}
  </div>
)

const Nav = ({ hideTools }) => {
  const ref = useRef()
  const onClick = useCallback(conf => {
    user.chatMode = "main"
    const hasChange = Object.keys(conf).find(k => user[k] !== conf[k])
    if (!hasChange) {
      user.viewMode = "app"
      return
    }
    Object.keys(conf).forEach(k => (user[k] = conf[k]))
  }, [])
  return useObserver(() => {
    return (
      <div
        ref={ref}
        css={css`
          position: absolute;
          left: 0;
          top: 3em;
          bottom: 4em;
          width: 100%;
          max-width: 22em;
          pointer-events: all;
          overflow: hidden;

          @media (min-width: 70em) {
            top: 4em;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            padding-right: 50px;
            overflow: auto;
            height: 100%;
          `}
        >
          <NavGroup>
            <NavLabel>TOOLS</NavLabel>
            {dj.player && (
              <Button
                showLabel={user.viewMode === "app"}
                label={`Sound ${dj.muted ? "On" : "Off"}`}
                icon={`/icons/speaker-${dj.muted ? "off" : "on"}.png`}
                navRef={ref.current}
                onClick={() => {
                  dj.setMuted(!dj.muted)
                }}
              />
            )}
            <Button
              showLabel={user.viewMode === "app"}
              label="Info"
              icon="/icons/alien.png"
              count={
                users.info.filter(u => u.viewMode === "instructions").length
              }
              navRef={ref.current}
              onTap={() => {
                onClick({
                  viewMode: "instructions",
                })
              }}
              title={users.info
                .filter(u => u.viewMode === "instructions")
                .map(u => u.nickName)
                .join(", ")}
              isActive={user.viewMode === "instructions"}
            />
          </NavGroup>
          <NavGroup>
            <NavLabel>DJ</NavLabel>
            {twitches
              .filter(t => t.enabled && t.isDj)
              .sort((a, b) => a.sort - b.sort)
              .map(t => {
                const isActive = user.viewMode === "app"
                return (
                  <Button
                    showLabel={user.viewMode === "app"}
                    key={t.key}
                    label={t.label}
                    icon={t.icon}
                    count={users.info.filter(u => u.viewMode === "app").length}
                    onTap={() => {
                      onClick({
                        viewMode: "app",
                        twitchChannel: t.channel,
                        chatMode: "main",
                      })
                    }}
                    isActive={isActive}
                  />
                )
              })}
          </NavGroup>
          <NavGroup>
            <NavLabel>ISLAND</NavLabel>
            {twitches
              .filter(t => t.enabled && !t.isDj)
              .sort((a, b) => a.sort - b.sort)
              .map(t => {
                const isActive =
                  user.viewMode === "twitch" && user.twitchChannel === t.channel
                return (
                  <Button
                    showLabel={user.viewMode === "app"}
                    key={t.key}
                    label={t.label}
                    icon={t.icon}
                    navRef={ref.current}
                    count={
                      users.info.filter(
                        u =>
                          u.viewMode === "twitch" &&
                          u.twitchChannel === t.channel
                      ).length
                    }
                    onTap={() => {
                      onClick({
                        viewMode: "twitch",
                        twitchChannel: t.channel,
                        chatMode: t.hasChat ? t.key : "main",
                      })
                    }}
                    isActive={isActive}
                  />
                )
              })}
          </NavGroup>
          <NavGroup>
            <NavLabel>CLUB</NavLabel>

            {rooms
              .filter(r => {
                return r.enabled === true
              })
              .sort((a, b) => {
                return parseInt(a.sort) > parseInt(b.sort) ? 1 : -1
              })
              .map(room => {
                const isActive =
                  user.viewMode === "jitsi" && user.jitsiRoom === room.name
                const _users = users.info.filter(
                  u => u.viewMode === "jitsi" && u.jitsiRoom === room.name
                )
                const maxUsers = room.maxUsers ? room.maxUsers : false
                const isDisabled = maxUsers && _users.length >= maxUsers
                return (
                  <Button
                    disabled={isDisabled}
                    showLabel={user.viewMode === "app"}
                    key={room.name}
                    navRef={ref.current}
                    label={room.label}
                    count={`${_users.length}${
                      !!room.maxUsers ? `  / ${room.maxUsers}` : ""
                    }`}
                    icon={room.icon}
                    onTap={() => {
                      onClick({
                        viewMode: "jitsi",
                        jitsiRoom: room.name,
                      })
                    }}
                    title={_users.map(u => u.nickName).join(", ")}
                    isActive={isActive}
                  />
                )
              })}
            <div
              css={css`
                height: 0.4em;
              `}
            />
          </NavGroup>
          {/*
          <NavGroup>
            <NavLabel>PLEASE DONATE</NavLabel>

            <Button
              showLabel={user.viewMode === "app"}
              label="Thank You❤"
              navRef={ref.current}
              icon="/icons/donate.png"
              onTap={() => {
                window.open("https://woff.payrexx.com", "donate")
                socket.sendChat("I spend some coins!!!")
              }}
            />
            </NavGroup>*/}
          <AnimatePresence>
            {user.viewMode !== "app" && (
              <motion.button
                key={user.viewMode}
                initial={{ x: "100%", opacity: 0 }}
                exit={{ x: "100%", opacity: 0 }}
                animate={{ x: "0%", opacity: 1 }}
                onTap={() => {
                  onClick({
                    viewMode: "app",
                    chatMode: "main",
                  })
                }}
                css={theme => [
                  theme.resetButton,
                  css`
                    position: fixed;
                    cursor: pointer;
                    font-size: 200%;
                    z-index: 4;
                    right: 0;
                    background: black;
                    color: white;
                    top: 0;
                    padding: 0.4em;
                    line-height: 0.4;
                  `,
                ]}
              >
                x
              </motion.button>
            )}
          </AnimatePresence>
        </div>
      </div>
    )
  })
}

export default Nav
