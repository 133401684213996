import React from "react"
import { useObserver } from "mobx-react"

import { user, dj, rooms, twitches } from "../store"
import BgVideo from "./bgvideo"
import TwitchStream from "./twitchStream"

const channel = "woffthecosmologicalorder"

export default () => {
  return useObserver(() => {
    const defaultJit =
      !!twitches.length &&
      twitches
        .filter(t => t.enabled && t.isDj)
        .sort((a, b) => a.sort - b.sort)[0]
    const currentJitsiRoom =
      user.viewMode === "jitsi" &&
      user.jitsiRoom &&
      rooms.find(r => r.name === user.jitsiRoom)
    const currentTwitchRoom =
      user.viewMode === "twitch" &&
      user.twitchChannel &&
      twitches.find(t => t.channel === user.twitchChannel)
    return (
      <BgVideo>
        <TwitchStream
          channel={
            currentTwitchRoom
              ? currentTwitchRoom.channel
              : defaultJit
              ? defaultJit.channel
              : channel
          }
          volume={
            user.viewMode === "jitsi" &&
            currentJitsiRoom &&
            typeof currentJitsiRoom.djVolume !== "undefined"
              ? currentJitsiRoom.djVolume
              : 0.5
          }
          onSetPlayer={player => {
            dj.player = player
            dj.setMuted(player.getMuted())
          }}
        />
      </BgVideo>
    )
  })
}
