import { useObserver } from "mobx-react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import { user } from "../store"
import YtPlayer from "./ytVideo"
import Overlay from "./overlay"

const Instructions = () => {
  return useObserver(() => (
    <Overlay
      isOpen={user.viewMode === "instructions"}
      onClose={() => (user.viewMode = "app")}
      checkSize={false}
      css={css`
        position: absolute;
        top: 50%;
        left: 0.4em;
        right: 0.4em;
        padding-top: 2em;
        bottom: auto;
        transform: translate(0, -50%);
        pointer-events: all;
      `}
    >
      <YtPlayer
        videoId="id5Hmj_OQEk"
        onEnd={() => (user.viewMode = "app")}
        config={{ loop: false }}
      />
    </Overlay>
  ))
}

export default Instructions
