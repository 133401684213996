import { useObserver } from "mobx-react"
import { useCallback, useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { user } from "../store"
import Overlay from "./overlay"
import TwitchStream from "./twitchStream"

const Inner = ({ channel }) => {
  const [width, setWidth] = useState("100%")
  const ref = useRef()
  useEffect(() => {
    setWidth("100%")
  }, [ref])

  useEffect(() => {
    if (!ref.current) return
    const onResize = () => {
      if (ref.current.clientHeight > window.innerHeight) {
        setWidth((window.innerWidth / 16) * 9 + "px")
      }
    }
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [ref, width])

  return (
    <div
      ref={ref}
      css={css`
        width: ${width};
        margin-left: auto;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            & iframe {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
            }
          `}
        >
          <TwitchStream channel={channel} volume={1} />
        </div>
      </div>
    </div>
  )
}

export default () => {
  const close = useCallback(() => {
    user.viewMode = "app"
    user.chatMode = "main"
  }, [])

  return useObserver(() => (
    <Overlay
      isOpen={user.viewMode === "twitch"}
      motionKey={user.twitchChannel}
      onClose={close}
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        padding-top: 2em;
        bottom: auto;
        transform: translate(-50%, -50%);
      `}
    >
      <Inner channel={user.twitchChannel} />
    </Overlay>
  ))
}
