import { useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

export default ({ videoId, onEnd = () => {}, config = {}, ...props }) => {
  const ref = useRef()
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    if (!ref.current) return
    if (player) return
    setPlayer(
      new window.YT.Player(ref.current, {
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
          loop: 1,
          fs: 0,
          cc_load_policy: 0,
          iv_load_policy: 3,
          autohide: 0,
          playsinline: 1,
          ...config,
        },
        events: {
          onReady: e => {
            // e.target.mute()
          },
          onStateChange: e => {
            switch (e.data) {
              case 0: // ended
                onEnd()
                break
              default:
                break
            }
          },
        },
      })
    )
  }, [player, ref, videoId, config, onEnd])

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          ref={ref}
          css={css`
            width: 100%;
            height: 100%;
          `}
          {...props}
        />
      </div>
    </div>
  )
}
