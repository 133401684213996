import { useObserver } from "mobx-react"
import { useRef, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { user, socket, rooms, dj } from "../store"
import Overlay from "./overlay"

const Inner = ({ nickName, roomName, meetMeLabel, decoration, muteAudio }) => {
  const ref = useRef()
  const [api, setApi] = useState(null)

  useEffect(() => {
    if (!ref.current) return
    const domain = "meet.jit.si"
    const options = {
      roomName: roomName,
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
      parentNode: ref.current,
      configOverwrite: {
        desktopSharingChromeDisabled: true,
        desktopSharingFirefoxDisabled: true,
        enableWelcomePage: false,
      },
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        DISABLE_VIDEO_BACKGROUND: true,
        HIDE_KICK_BUTTON_FOR_GUESTS: true,
        TOOLBAR_BUTTONS: [],
      },
    }
    const _api = new window.JitsiMeetExternalAPI(domain, options)
    setApi(_api)

    _api.on("videoConferenceJoined", ({ id }) => {
      _api.executeCommand("toggleTileView")
      if (meetMeLabel) socket.sendChat(meetMeLabel)
    })
    _api.on("audioMuteStatusChanged", ({ muted }) => {
      if (!!muteAudio && !muted) {
        // always mute audio
        _api.executeCommand("toggleAudio")
        // always play dj
        dj.setMuted(false)
        dj.setVolume(1)
      }
    })
    _api.on("tileViewChanged", ({ enabled }) => {
      if (!enabled) {
        // always leave tile view on
        _api.executeCommand("toggleTileView")
      }
    })

    return () => {
      _api.dispose()
      setApi(null)
    }
  }, [muteAudio, ref, roomName, meetMeLabel])

  useEffect(() => {
    if (!api) return
    api.executeCommand("displayName", nickName)
  }, [api, nickName])

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: all;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          background-image: url(${decoration});
          background-size: 12em auto;
          background-repeat: no-repeat;
          background-position: top center;
        }

        & iframe {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }
      `}
    />
  )
}

export default () => {
  return useObserver(() => {
    const room = rooms.find(r => r.name === user.jitsiRoom)
    return (
      <Overlay
        isOpen={user.viewMode === "jitsi"}
        onClose={() => {
          user.viewMode = "app"
        }}
        motionKey={user.jitsiRoom}
      >
        <Inner
          nickName={user.nickName}
          roomName={user.jitsiRoom}
          meetMeLabel={!!room && room.meetMeLabel}
          decoration={!!room && room.decoration}
          muteAudio={!!room && !!room.muteAudio}
        />
      </Overlay>
    )
  })
}
