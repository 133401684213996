import { Fragment } from "react"
import { useObserver } from "mobx-react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import { socket, users, user } from "../store"
import LabelValue from "./labelValue"
import ChatOutput from "./chat/chatOutput"
import ChatInput from "./chat/ChatInput"

const UserInfo = props => {
  return useObserver(() => (
    <div
      css={css`
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
      `}
      {...props}
    >
      <LabelValue
        label={`me (${
          socket.isOpen
            ? "connected"
            : socket.isConnecting
            ? "connecting"
            : "diconnected"
        })`}
        value={user.nickName}
        css={css`
          display: none;
          @media (min-width: 30em) {
            display: block;
            flex: 1 1 auto;
          }
        `}
      />
      <div
        css={css`
          flex: 0 0 0.4em;
        `}
      />
      {user.chatMode === "main" && (
        <Fragment>
          <LabelValue
            label="online"
            value={
              users.info &&
              users.info.filter(u => !!u.viewMode && u.viewMode !== "admin")
                .length
            }
            textAlign="center"
            css={css`
              display: none;
              @media (min-width: 30em) {
                flex: 1 1 auto;
                display: block;
              }
            `}
          />
          <div
            css={css`
              flex: 0 0 0.4em;
            `}
          />
          {/*
          <LabelValue
            label="insta-fotowall"
            textAlign="right"
            value={
              <a
                href="https://instagram.com/a/r/?effect_id=722873805207808"
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  color: inherit;
                  pointer-events: all;
                `}
              >
                go there!
              </a>
            }
            css={css`
              flex: 1 1 auto;
            `}
          />
          <div
            css={css`
              flex: 0 0 0.4em;
            `}
          />*/}
        </Fragment>
      )}
      <LabelValue
        textAlign="left"
        label={`chat ${user.chatMode}`}
        css={css`
          flex: 1 1 ${user.chatMode === "main" ? "auto" : "100%"};
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <ChatOutput chatId={user.chatMode} />
          <ChatInput chatId={user.chatMode} />
        </div>
      </LabelValue>
    </div>
  ))
}

export default UserInfo
